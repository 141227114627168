import React, { useState, useEffect } from "react";
import img1 from "../Assets/fin.png";
import img7 from "../Assets/fin7.webp";
import img8 from "../Assets/fin8.webp";

const CarouselFrame = () => {
  const slides = [
    {
      image: img1,
      title: "ERP Solution",
      description:
        "Solution that can re-define the Business to maximize the profitability.",
    },
    {
      image: img7,
      title: "HRM Solution",
      description:
        "Lead the people to success, with the solution that can blend with Management and Employees.",
    },
    {
      image: img8,
      title: "CRM for Businesses",
      description:
        "Secure your Customers with solid CRM Solution, that enables a guided communication.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically change slides every 7 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 7000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [slides.length]);

  return (
    <div className="relative w-full h-[300px] sm:h-[400px] lg:h-[500px] 2xl:h-[600px]">
      {/* Slide */}
      <div
        className="absolute inset-0 transition-all duration-500"
        style={{
          backgroundImage: `url(${slides[currentIndex].image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Text Box */}
        <div className="absolute top-1/2 left-[20px] sm:left-[40px] lg:left-[70px] transform -translate-y-1/2 text-white">
          <h2 className="text-xl sm:text-3xl lg:text-5xl 2xl:text-6xl font-bold drop-shadow-lg">
            {slides[currentIndex].title}
          </h2>
          <p className="mt-2 sm:mt-4 text-sm sm:text-base lg:text-lg drop-shadow-lg">
            {slides[currentIndex].description}
          </p>
        </div>
      </div>

      {/* Indicators */}
      <div className="absolute bottom-4 sm:bottom-6 flex justify-center w-full">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 sm:w-4 sm:h-4 mx-1 sm:mx-2 rounded-full transition-all duration-300 ${
              index === currentIndex ? "bg-white scale-125" : "bg-gray-500"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default CarouselFrame;
