import React from "react";
import pic1 from "../Assets/pic1.png";

const SubMenuPageContent = ({ activePage }) => {
  // Content for each page
  const SubMenuPageContent = {
    Overview: {
      title: "Delivering Stability to the Business",
      paragraphs: [
        "In today’s fast-paced business environment, staying competitive requires more than just hard work, it requires efficiency, insight, and adaptability. Our Enterprise Resource Planning (ERP) solution is designed to bring all your core business processes into one unified platform, enabling seamless integration, improved visibility, and enhanced decision-making.",
        "Our ERP system centralizes your key functions such as finance, inventory, sales, and human resources, reducing redundancies and increasing productivity. Automate repetitive tasks and focus on strategic goals. With real-time insights and analytics, our ERP helps you make data-driven decisions, minimizing risks and ensuring business stability.",
        "Designed to grow with your business, the system adapts effortlessly to your needs, whether you’re expanding into new markets or scaling operations.",
      ],
      image: pic1,
    },
    Benefits: {
      points: [
        {
          heading: "Anytime, Anywhere Access",
          content:
            "Empower your team with secure, real-time access to critical business data from any device, anywhere in the world. Stay connected and productive, whether in the office, on the road, or working remotely.",
        },
        {
          heading: "Cost-Effective Scalability",
          content:
            "Reduce upfront IT costs with our subscription-based model. As your business grows, seamlessly scale resources without the need for costly hardware upgrades or lengthy implementations.",
        },
        {
          heading: "Robust Data Security",
          content:
            "Your data is safeguarded with advanced encryption, regular backups, and compliance with global security standards. Focus on growing your business while we handle your IT security.",
        },
        {
          heading: "Rapid Deployment",
          content:
            "Say goodbye to lengthy installations and complex setups. Our Cloud ERP solution is quick to deploy, getting you operational in no time and minimizing business disruption.",
        },
      ],
      image: pic1,
    },
    Capabilities: {
      title: "",
      points: [
        {
          heading: "Comprehensive Business Process Automation",
          content:
            "Seamless Integration: Integrate all core business functions such as finance, HR, sales, procurement, and inventory into a unified platform.\nEnd-to-End Automation: Eliminate manual processes with robust automation tools, reducing errors and increasing efficiency.\nCustom Workflows: Tailor workflows to meet the unique needs of businesses across industries.",
        },
        {
          heading: "Real-Time Analytics and Insights",
          content:
            "Data Centralization: Centralize business data to provide a single source of truth for better decision-making.\nInteractive Dashboards: Deliver real-time, customizable dashboards for KPIs and metrics.",
        },
        {
          heading: "Robust Security and Compliance",
          content:
            "Enterprise-Grade Security: Protect sensitive data with advanced encryption, multi-factor authentication, and regular vulnerability assessments.",
        },
      ],
      image: pic1,
    },
    "How to Buy": {
      title: "",
      description:
        "Our customers can purchase our products and services through qualified partners in their respective regions or directly subscribe through our internal sales.",
      options: [
        {
          heading:
            "If you want to Buy our Cloud Solutions, Please find our Cloud Solution Partners",
          buttonText: "Find our Partners",
        },
        {
          heading:
            "If you want to Buy our On-premise Solutions, Please find our On-Premise Service Delivery Partners",
          buttonText: "Find our Partners",
        },
      ],
      image: pic1,
    },
    "Success Stories":
      "Read success stories from our clients who have achieved excellence.",
    Resources:
      "Explore our resources including guides, tutorials, and FAQs to help you succeed.",
  };

  // Custom layout for Overview, Benefits, Capabilities, and How to Buy
  if (
    ["Overview", "Benefits", "Capabilities", "How to Buy"].includes(activePage)
  ) {
    const {
      title,
      paragraphs,
      points,
      image,
      description,
      options,
    } = SubMenuPageContent[activePage];

    return (
      <div className="w-full flex flex-col lg:flex-row mt-4 sm:mt-6">
        {/* Left Side: Text */}
        <div className="lg:w-2/3 bg-gray-100 flex flex-col items-start p-8">
          <h2 className="text-[18px] font-bold mb-4">{title}</h2>
          {activePage === "Overview" &&
            paragraphs.map((text, index) => (
              <p
                key={index}
                className="text-[13px] mb-4 text-gray-700 leading-relaxed"
              >
                {text}
              </p>
            ))}

          {["Benefits", "Capabilities"].includes(activePage) &&
            points.map((point, index) => (
              <div key={index} className="mb-6">
                <h3 className="text-[14px] font-semibold text-gray-900 mb-1">
                  {point.heading}
                </h3>
                <p className="text-[13px] text-gray-700 leading-relaxed whitespace-pre-line">
                  {point.content}
                </p>
              </div>
            ))}

          {activePage === "How to Buy" && (
            <>
              <p className="text-[13px] text-gray-700 mb-6">{description}</p>
              {options.map((option, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-[14px] font-semibold text-gray-900 mb-2">
                    {option.heading}
                  </h3>
                  <button className="bg-blue-500 text-white text-sm py-2 px-4 rounded shadow hover:bg-blue-600 w-[150px]">
                    {option.buttonText}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        {/* Right Side: Image */}
        <div className="lg:w-1/3 h-[300px]">
          <img
            src={image}
            alt={`${activePage} Background`}
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    );
  }

  // Default rendering for other tabs
  return (
    <div className="p-4 sm:p-6 max-w-[95%] sm:max-w-[85%] mx-auto">
      <div className="h-auto sm:h-[400px] lg:h-[500px] overflow-y-auto">
        <h1 className="text-2xl sm:text-3xl font-bold text-center">
          {activePage}
        </h1>
        <p className="mt-4 text-sm sm:text-base text-center text-gray-700 leading-relaxed">
          {SubMenuPageContent[activePage]}
        </p>
      </div>
    </div>
  );
};

export default SubMenuPageContent;
