import React from "react";
import img2 from "../Assets/pic3.png";

const BodyFrameBox3 = () => {
  return (
    <div
      className="w-full h-[250px] sm:h-[300px] lg:h-[350px] 2xl:h-[400px] bg-cover bg-center flex flex-col justify-center items-center mt-9 text-white"
      style={{
        backgroundImage: `url(${img2})`, // Replace with your image URL
      }}
    >
      {/* Text */}
      <h2 className="text-base sm:text-xl lg:text-2xl 2xl:text-3xl font-bold mb-4 text-center px-4 sm:px-8">
        We help business with 360° solution
      </h2>

      {/* Button */}
      <button className="px-4 py-2 sm:px-6 sm:py-3 bg-sky-700 hover:bg-sky-800 rounded-md shadow-md text-white text-xs sm:text-sm lg:text-base font-medium transition-all duration-300">
        Consult with our Expert
      </button>
    </div>
  );
};

export default BodyFrameBox3;
