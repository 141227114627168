import React from "react";
import hrmsimg1 from "../Assets/hrms1.png";
import fin2 from "../Assets/finance2.png";
import mgdimg from "../Assets/mgdsvc1.png";

const BodyFrameBox6 = () => {
  const boxes = [
    {
      title: "Solving the Puzzles in HR Management",
      description:
        "We understand that managing human resources can often feel like solving a complex puzzle. Our HR management solutions are designed to bring all the pieces together, creating a seamless and efficient process for workforce management.\n\nFrom recruitment and onboarding to payroll, performance tracking, and compliance, our solutions simplify every aspect of HR operations. By leveraging cutting-edge tools and customizable features, we help you optimize workflows, improve employee engagement, and make data-driven decisions.",
      image: hrmsimg1, // Replace with your image URL
    },
    {
      title: "Project Based Accounting",
      description:
        "Effectively managing finances for individual projects is critical for businesses striving for accuracy and profitability. Our project-based accounting solutions empower you to track, analyze, and manage financial data at the project level with precision and ease.\n\nDesigned to fit for project-driven organizations, our ERP-integrated tools provide real-time visibility into project costs, budgets, revenue, and profitability. From allocating resources and tracking expenses to generating accurate invoices and monitoring performance, our solution ensures that every financial aspect of your project is accounted for.",
      image: fin2, // Replace with your image URL
    },
    {
      title: "Managed Services",
      description:
        "Our Managed Services go beyond deployment. We partner with you to not only implement cutting-edge ERP solutions but also to guide your organization toward modern, streamlined operations. Our mission is to empower your business with the tools and expertise needed to thrive in an ever-evolving digital landscape.\n\nYou gain more than a service provider—you gain a strategic partner dedicated to driving your success. Together, we’ll transform your operations into a modern, future-ready enterprise.",
      image: mgdimg, // Replace with your image URL
    },
  ];

  return (
    <div className="w-full mt-9 px-4 sm:px-10 lg:px-20">
      {/* Main Title */}
      <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-6 sm:mt-[40px] mb-8">
        Latest News and Updates
      </h2>

      {/* Boxes Container */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {boxes.map((box, index) => (
          <div
            key={index}
            className="flex flex-col border border-gray-300 rounded-lg overflow-hidden shadow-md"
          >
            {/* Image at the Top */}
            <div
              className="w-full h-[180px] sm:h-[200px] lg:h-[220px] bg-cover bg-center"
              style={{
                backgroundImage: `url(${box.image})`,
              }}
            ></div>
            {/* Text Area */}
            <div className="p-4 bg-gray-100 flex flex-col justify-between h-auto">
              {/* Left-Aligned Heading */}
              <h3 className="text-[14px] lg:text-[16px] font-bold mb-2 text-left">
                {box.title}
              </h3>
              {/* Description */}
              <div className="text-gray-700 text-[12px] lg:text-[13px] flex-1 overflow-hidden">
                {box.description.split("\n\n").map((paragraph, idx) => (
                  <p key={idx} className="mb-2 leading-relaxed">
                    {paragraph}
                  </p>
                ))}
              </div>
              {/* Read More */}
              <div className="text-right">
                <a
                  href="#"
                  className="text-blue-500 text-[12px] lg:text-[13px] hover:underline"
                >
                  Read More...
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyFrameBox6;
