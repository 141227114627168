import React, { useState, useEffect, useRef } from "react";
import logo from "../Assets/praivacylogo.png";

const TopMainMenu = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null); // Track the currently open menu
  const menuRef = useRef(null); // Reference for the menu container

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMenu = (menuName) => {
    setOpenMenu((prev) => (prev === menuName ? null : menuName));
  };

  // Close dropdown if clicking outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuItems = [
    { name: "Products", submenu: ["ERP Cloud", "ERP On-premise", "Product 3"] },
    { name: "Solutions", submenu: ["Solution 1", "Solution 2", "Solution 3"] },
    { name: "Services", submenu: ["Service 1", "Service 2", "Service 3"] },
    { name: "Industries", submenu: ["Industry 1", "Industry 2", "Industry 3"] },
    { name: "Partners", submenu: [] },
    { name: "Support", submenu: [] },
    { name: "Buy", submenu: [] },
    { name: "Resources", submenu: [] },
  ];

  return (
    <>
      {/* Top Main Menu Bar */}
      <div
        className="w-full h-[80px] bg-white flex items-center px-4 shadow-md fixed z-50"
        style={{ marginTop: "10px" }}
      >
        {/* Hamburger Icon for Mobile */}
        <div
          className="text-gray-900 cursor-pointer text-2xl lg:hidden"
          onClick={toggleSidebar}
        >
          &#9776; {/* Hamburger menu icon */}
        </div>

        {/* Logo */}
        <div className="absolute right-4 sm:left-[30px] lg:left-[15px]">
          <img
            src={logo}
            alt="Logo"
            className="w-[180px] h-[60px] object-contain" // Logo frame adjusted for all screens
          />
        </div>

        {/* Menu for Desktop */}
        <div
          ref={menuRef}
          className="hidden lg:flex gap-6 text-gray-900 lg:text-[16px] 2xl:text-[17px] w-full lg:ml-[260px] 2xl:ml-[250px]"
        >
          <ul className="flex gap-6">
            {menuItems.map((menu) => (
              <li key={menu.name} className="relative group">
                <button
                  onClick={() => toggleMenu(menu.name)}
                  className="cursor-pointer hover:text-blue-500 focus:outline-none relative pb-2"
                >
                  {menu.name}
                  {/* Blue Underline */}
                  <span className="absolute bottom-[-2px] left-0 w-0 h-[2px] bg-blue-500 transition-all duration-300 group-hover:w-full"></span>
                </button>
                {/* Dropdown submenu */}
                {menu.submenu.length > 0 && openMenu === menu.name && (
                  <ul className="absolute left-0 bg-white shadow-md rounded-lg mt-2 max-w-[600px] w-400px">
                    {menu.submenu.map((submenu, index) => (
                      <li
                        key={index}
                        className="px-2 py-1 text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer truncate"
                      >
                        {submenu}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Sidebar (Visible on Mobile Only) */}
      <div
        className={`fixed top-[111px] left-0 h-[calc(100%-90px)] bg-white text-gray-900 transition-transform duration-300 ease-in-out lg:hidden z-50 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        style={{ width: "50%" }}
      >
        <ul className="p-4 space-y-4">
          {menuItems.map((menu) => (
            <li key={menu.name}>
              <div
                className="flex justify-between items-center cursor-pointer hover:bg-blue-500 hover:text-white px-4 py-2 rounded"
                onClick={() => toggleMenu(menu.name)}
              >
                <span>{menu.name}</span>
                {menu.submenu.length > 0 && (
                  <span>
                    {openMenu === menu.name ? "-" : "+"}{" "}
                    {/* Toggle Indicator */}
                  </span>
                )}
              </div>
              {/* Submenu for Mobile */}
              {menu.submenu.length > 0 && openMenu === menu.name && (
                <ul className="pl-6 space-y-2 mt-2">
                  {menu.submenu.map((submenu, index) => (
                    <li
                      key={index}
                      className="cursor-pointer hover:bg-blue-500 hover:text-white px-4 py-2 rounded"
                      onClick={() => setIsSidebarOpen(false)} // Close sidebar after selecting
                    >
                      {submenu}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Overlay (Visible on Mobile Only) */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default TopMainMenu;
