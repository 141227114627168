import React from "react";
import facebooklogo from "../Assets/fb.png";
import linkedinlogo from "../Assets/li.png";
import xlogo from "../Assets/x.png";
import youtubelgo from "../Assets/youtube.png";
import praivacylogo from "../Assets/praivacyfooterlogo.png";

const FooterFrameBox = () => {
  return (
    <div className="w-full bg-black text-white px-6 py-10 mt-[30px]">
      {/* Footer Content */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 px-4">
        {/* First Block - Logo and Company Details */}
        <div>
          <img src={praivacylogo} alt="Logo" className="mb-4 w-40" />
          <p className="text-sm">
            prAIvacy ERP LLC.
            <br />
            1309 Coffeen Avenue, STE 1200
            <br />
            Sheridan Wyoming 82801, United States.
            <br />
            Email: info@praivacyerp.com
          </p>
        </div>

        {/* Second Block - Company (With Gap from Left) */}
        <div className="lg:ml-10 2xl:ml-14">
          <h3 className="text-lg font-bold mb-4">Company</h3>
          <ul className="text-sm space-y-2">
            <li>About</li>
            <li>Solutions</li>
            <li>Strategy</li>
            <li>Policy</li>
            <li>Philosophy</li>
          </ul>
        </div>

        {/* Third Block - Our Solutions */}
        <div>
          <h3 className="text-lg font-bold mb-4">Our Solutions</h3>
          <ul className="text-sm space-y-2">
            <li>ERP Solutions</li>
            <li>HRMS Solutions</li>
            <li>Finance & Accounting Solutions</li>
            <li>Managed Services</li>
          </ul>
        </div>

        {/* Fourth Block - Quick Links */}
        <div>
          <h3 className="text-lg font-bold mb-4">Quick Links</h3>
          <ul className="text-sm space-y-2">
            <li>Contact Center</li>
            <li>Support Center</li>
            <li>Clients</li>
            <li>Channel Partners</li>
            <li>Industries</li>
          </ul>
        </div>

        {/* Fifth Block - Social Media Logos */}
        <div>
          <h3 className="text-lg font-bold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebooklogo}
                alt="Facebook Logo"
                className="h-[25px]"
              />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinlogo}
                alt="LinkedIn Logo"
                className="h-[25px]"
              />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xlogo} alt="X Logo" className="h-[25px]" />
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubelgo} alt="Youtube Logo" className="h-[25px]" />
            </a>
          </div>
        </div>
      </div>

      {/* Rights and Disclaimer */}
      <div className="w-full text-center text-[12px] text-gray-500 mt-8 px-4">
        <p className="whitespace-nowrap text-white mb-2">
          All Rights Reserved by prAIvacy ERP LLC.
        </p>
        Disclaimer: All content, trademarks, logos, and assets displayed on this
        website are the exclusive property of prAIvacy ERP LLC, a subsidiary of
        NAULOGICA SYSTEMS LLC, United States. Unauthorized use, reproduction, or
        distribution is strictly prohibited and may result in legal action. For
        inquiries, please contact us at [info@praivacyerp.com].
      </div>
    </div>
  );
};

export default FooterFrameBox;
