import React from "react";
import orbislogo from "../Assets/orbislogo.png";
import toshibalogo from "../Assets/toshiba.png";
import ractlogo from "../Assets/ractlogo.png";
import majesticlogo from "../Assets/majesticlogo.png";
import badialogo from "../Assets/badia.png";
import alliancelogo from "../Assets/alliance.png";

const BodyFrameBox5 = () => {
  const boxes = [
    { title: "Box 1", image: toshibalogo },
    { title: "Box 2", image: orbislogo },
    { title: "Box 3", image: ractlogo },
    { title: "Box 4", image: majesticlogo },
    { title: "Box 5", image: badialogo },
    { title: "Box 6", image: alliancelogo },
  ];

  return (
    <div className="w-full mt-9">
      {/* Main Title */}
      <h2 className="text-center text-xl sm:text-2xl font-bold mt-[20px] sm:mt-[40px] mb-6">
        Client List in the Region
      </h2>

      {/* Grid Layout */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 sm:gap-6 px-4 sm:px-6 lg:px-8">
        {boxes.map((box, index) => (
          <div
            key={index}
            className="relative border-2 border-sky-600 rounded-lg overflow-hidden flex items-center justify-center"
          >
            {/* Image */}
            <img
              src={box.image}
              alt={box.title}
              className="w-full max-h-[60px] sm:max-h-[70px] object-contain p-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyFrameBox5;
