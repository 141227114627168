import React, { useState } from "react";
import TopMenu from "./Components/TopMenu";
import TopMainMenu from "./Components/TopMainMenu";
import FooterFrameBox from "./Components/FooterFrameBox";
import CarouselFrame from "./Components/CarouselFrame";
import SubMenuBar from "./Components/SubMenuBar";
import SubMenuPageContent from "./Components/SubMenuPageContent";
import BodyFrameBox2 from "./Components/BodyFrameBox2";
import BodyFrameBox3 from "./Components/BodyFrameBox3";
import BodyFrameBox4 from "./Components/BodyFrameBox4";
import BodyFrameBox5 from "./Components/BodyFrameBox5";
import BodyFrameBox6 from "./Components/BodyFrameBox6";
function App() {
  const [activePage, setActivePage] = useState("Overview"); // Track active submenu

  return (
    <div className="flex flex-col min-h-screen">
      {/* Top Menu */}
      <TopMenu />

      {/* Top Main Menu */}
      <div className="mt-[20px]">
        <TopMainMenu />
      </div>

      {/* Carousel Section */}
      <main className="flex-1">
        <CarouselFrame />
      </main>

      {/* Sub Menu Bar */}
      <div className="mt-6 w-full">
        <SubMenuBar setActivePage={setActivePage} activePage={activePage} />
      </div>

      {/* Main Content Based on Active Page */}
      <SubMenuPageContent activePage={activePage} />

      {/* Services Section */}
      <BodyFrameBox2 />

      {/* Services Section */}
      <BodyFrameBox3 />

      {/* Services Section */}
      <BodyFrameBox4 />

      {/* Services Section */}
      <BodyFrameBox5 />

      {/* Services Section */}
      <BodyFrameBox6 />

      {/* Footer */}
      <FooterFrameBox />
    </div>
  );
}

export default App;
