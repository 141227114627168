import React, { useState } from "react";

const TopMenu = () => {
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);

  const toggleCompanyDropdown = () => {
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
    setIsLoginDropdownOpen(false); // Close other dropdown when opening this one
  };

  const toggleLoginDropdown = () => {
    setIsLoginDropdownOpen(!isLoginDropdownOpen);
    setIsCompanyDropdownOpen(false); // Close other dropdown when opening this one
  };

  return (
    <div className="w-full h-[30px] bg-gray-400 flex items-center px-4 fixed top-0 z-50">
      <div className="w-full flex justify-end items-center pr-4 lg:pr-0 2xl:pr-[30px]">
        {/* Right-side Menu Items */}
        <div className="text-gray-900 text-[13px] flex gap-8 items-center relative">
          {/* Company Dropdown */}
          <div className="flex items-center gap-1 relative">
            <span>Company</span>
            <button
              className="text-gray-900"
              onClick={toggleCompanyDropdown}
              aria-label="Toggle Company Dropdown"
            >
              {isCompanyDropdownOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </button>

            {isCompanyDropdownOpen && (
              <div className="absolute top-[30px] right-0 bg-white text-black text-[12px] shadow-md rounded-lg p-2">
                <ul className="flex flex-col gap-2">
                  <li className="cursor-pointer hover:text-blue-500">
                    About Us
                  </li>
                  <li className="cursor-pointer hover:text-blue-500">
                    Careers
                  </li>
                  <li className="cursor-pointer hover:text-blue-500">
                    Contact
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* Login Dropdown */}
          <div className="flex items-center gap-1 relative">
            <span>Login</span>
            <button
              className="text-gray-900"
              onClick={toggleLoginDropdown}
              aria-label="Toggle Login Dropdown"
            >
              {isLoginDropdownOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </button>

            {isLoginDropdownOpen && (
              <div className="absolute top-[30px] right-0 bg-white text-black text-[12px] shadow-md rounded-lg p-2">
                <ul className="flex flex-col gap-2">
                  <li className="cursor-pointer hover:text-blue-500">Admin</li>
                  <li className="cursor-pointer hover:text-blue-500">User</li>
                  <li className="cursor-pointer hover:text-blue-500">Guest</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
