import React from "react";
import bconsultimg from "../Assets/pic4.png";
import solutionimg from "../Assets/pic5.png";
import deployimg from "../Assets/pic6.png";
import magsvcimg from "../Assets/pic7.png";

const BodyFrameBox2 = () => {
  const boxes = [
    {
      title: "Business Consulting",
      description:
        "prAIvacy Consulting, empower businesses to achieve operational excellence through tailored ERP business solutions. Our Business Consulting services are designed to streamline processes, enhance efficiency, and drive growth. With a deep understanding of industry-specific needs, our consultants collaborate closely with your team to identify pain points, implement innovative strategies, and unlock your organization’s full potential. our expert guidance ensures your ERP investment delivers maximum value.",
      image: bconsultimg, // Replace with your image URL
    },
    {
      title: "Solution Customization",
      description:
        "From modifying modules to automating processes, we adapt the ERP framework to enhance productivity and scalability. By working closely with your team, we deliver a system that simplifies operations, improves decision-making, and grows with your business. Whether it’s integrating third-party tools, configuring dashboards, or building custom features, our experts ensure a solution that drives measurable results.",
      image: solutionimg, // Replace with your image URL
    },
    {
      title: "Deployment Services",
      description:
        "For businesses that demand complete control over their ERP infrastructure, our on-premise deployment services provide robust, internally hosted solutions tailored to your organization. On the other hand, for those prioritizing scalability and reduced IT overhead, our cloud deployment services offer a seamless, secure, and easily accessible alternative. By utilizing leading cloud platforms, we deliver customized ERP configurations designed to enable remote access, real-time updates, and data-driven insights, all while minimizing upfront expenses.",
      image: deployimg, // Replace with your image URL
    },
    {
      title: "Managed Expert Services",
      description:
        "Our expert team ensures seamless day-to-day management of your ERP systems, IT infrastructure, and operational processes, allowing you to focus on strategic growth.  paAIvacy MS team  ensure a smooth transition by equipping your team with the tools, training, and best practices needed to manage operations independently. ",
      image: magsvcimg, // Replace with your image URL
    },
  ];

  return (
    <div className="w-full mt-9 px-[25px]">
      {/* Title for All Blocks */}
      <h2 className="text-2xl font-bold text-center mb-6 text-black">
        Services
      </h2>

      {/* Grid of Boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {boxes.map((box, index) => (
          <div
            key={index}
            className="flex border-2 border-sky-600 rounded-lg overflow-hidden h-[200px]"
          >
            {/* Text Area */}
            <div className="w-3/5 bg-gray-100 flex flex-col justify-start p-4">
              {/* Heading */}
              <h3 className="text-lg font-bold mb-2">{box.title}</h3>
              {/* Description */}
              <p className="text-gray-700 text-[13px] line-clamp-6 overflow-hidden">
                {box.description}
              </p>
            </div>

            {/* Image Area */}
            <div
              className="w-2/5 bg-cover bg-center"
              style={{
                backgroundImage: `url(${box.image})`,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyFrameBox2;
