import React from "react";

const SubMenuBar = ({ setActivePage, activePage }) => {
  const menuItems = [
    { name: "Overview", alwaysVisible: true },
    { name: "Benefits", alwaysVisible: true },
    { name: "Capabilities", alwaysVisible: true },
    { name: "How to Buy", alwaysVisible: true },
    { name: "Success Stories", alwaysVisible: false }, // Hidden on mobile
    { name: "Resources", alwaysVisible: true },
  ];

  return (
    <div className="bg-white text-black w-full h-[50px] sm:h-[40px] shadow-lg flex items-center">
      <div className="flex justify-start w-full overflow-x-auto">
        {menuItems.map((item) => (
          <div
            key={item.name}
            onClick={() => setActivePage(item.name)}
            className={`px-3 sm:px-4 py-1 text-center font-medium cursor-pointer relative group ${
              !item.alwaysVisible ? "hidden sm:block" : ""
            }`}
          >
            <span className="text-[12px] sm:text-[14px] lg:text-[16px]">
              {item.name}
            </span>
            {/* Underline */}
            <div
              className={`absolute bottom-[2px] left-0 h-[2px] w-full bg-blue-600 scale-x-0 transition-transform duration-300 group-hover:scale-x-100 ${
                activePage === item.name ? "scale-x-100" : ""
              }`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubMenuBar;
