import React from "react";
import erplogo from "../Assets/erp1.png";
import financelogo from "../Assets/finance.png";
import hrmsimg from "../Assets/hrms.png";
import inventoryimg from "../Assets/inventory.png";
import crmimg from "../Assets/crmimg.png";
import posimg from "../Assets/pos.png";
import proejctimg from "../Assets/projectimg.png";
import procurementimg from "../Assets/procurement.png";

const BodyFrameBox4 = () => {
  const boxes = [
    { title: " ERP", image: erplogo },
    { title: " Finance", image: financelogo },
    { title: " HRMS", image: hrmsimg },
    { title: " Inventory", image: inventoryimg },
    { title: " CRM", image: crmimg },
    { title: " Sales", image: posimg },
    { title: "Project", image: proejctimg },
    { title: " Procurement", image: procurementimg },
  ];

  return (
    <div className="w-full mt-9">
      {/* Main Title */}
      <h2 className="text-center text-xl sm:text-2xl font-bold mb-6">
        Featured Products
      </h2>

      {/* Grid Layout */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 px-4 sm:px-8 lg:px-[25px]">
        {boxes.map((box, index) => (
          <div
            key={index}
            className="relative border-2 border-gray-300 rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300"
          >
            {/* Image */}
            <img
              src={box.image}
              alt={box.title}
              className="w-full h-[150px] sm:h-[180px] lg:h-[200px] object-cover"
            />

            {/* Title Overlay */}
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-[120px] sm:w-[140px] lg:w-[150px] h-[30px] bg-sky-900 text-white px-4 py-1 flex items-center justify-center text-center rounded-sm shadow-lg">
              <span className="text-xs sm:text-sm lg:text-base font-bold">
                {box.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyFrameBox4;
